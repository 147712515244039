import React, { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  CheckboxGroup,
  Checkbox,
  useDisclosure,
  Spacer,
  InputGroup,
  InputLeftElement,
  useToast,
  Stack,
} from "@chakra-ui/react";
import MainHeader from "../../components/form/header/MainHeader";
import { useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./form.module.css";
import TermsConditionsDrawer from "../../components/form/drawer/tnc-drawer";
import StoreDrawer from "../../components/form/drawer/store-drawer";
import ErrorModal from "../../components/otp/modals/errorModal";
import { usePostRegisterUserAndSendOTP } from "../../hooks/api/usePostRegisterUserAndSendOTP";
import { usePostAuthenticate } from "../../hooks/api/usePostAuthenticateUser";
import { useGetStoreData } from "../../hooks/api/useGetStoreData";
import { usePostVerifyCaptcha } from "../../hooks/api/usePostVerifyCaptcha";
import { useQueryClient } from "@tanstack/react-query";

import { ChevronUpIcon } from "@chakra-ui/icons";

function FormRegistration() {
  moment.locale();

  const toast = useToast();
  const queryClient = useQueryClient();
  const [params] = useSearchParams();
  const [store] = useState(params.get("store"))
  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [filteredStoreData, setFilteredStoreData] = useState([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isRequiredFormIncomplete, setIsRequiredFormIncomplete] = useState(true);
  const reCAPTCHA_SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

  const captchaRef = useRef(null);

  const fuzzyscore = 75;

  const {
    isOpen: isOpenTnCDrawer,
    onOpen: onOpenTnCDrawer,
    onClose: onCloseTnCDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenStoreDrawer,
    onOpen: onOpenStoreDrawer,
    onClose: onCloseStoreDrawer,
  } = useDisclosure();

  const closeModal = () => {
    setModalOpen(false);
  };

  const [formData, setFormData] = useState({
    user_name: "",
    dateofbirth: "",
    store: store || "",
    email_address: "",
    phone_number: "",
    is_accept: false,
  });

  const {
    mutate: mutateRegister,
    isLoading: isLoadingRegister,
    isSuccess: isSuccessRegister,
  } = usePostRegisterUserAndSendOTP();

  const {
    mutate: mutatePostAuthenticate,
    isLoading: isLoadingAuthenticate,
    isSuccess: isSuccessAuthenticate,
  } = usePostAuthenticate();

  const {
    mutate: mutatePostVerify,
    isLoading: isLoadingVerify,
    isSuccess: isSuccessVerify,
  } = usePostVerifyCaptcha();

  const { data: stores } = useGetStoreData(isEnabled);

  useEffect(() => {
    setIsEnabled(true);
    queryClient.invalidateQueries({
      queryKey: ["store", true],
    });
    setFilteredStoreData(stores);
  }, [queryClient, stores]);

  useEffect(() => {
    if (store) {
      setSelectedStoreName(store);
    } else if (localStorage.getItem("store")) {
      setSelectedStoreName(localStorage.getItem("store"));
    }
  }, [store]);

  const searchProduct = (event) => {
    const fuzz = require("fuzzball");
    const query = event.target.value;

    let tempFilteredStore = [];
    if (query !== "") {
      stores.forEach((store) => {
        const storeName = store.name;
        let productfuzz = 0;
        productfuzz = fuzz.partial_ratio(
          storeName.toLowerCase(),
          query.toLowerCase()
        );
        if (productfuzz >= fuzzyscore) {
          tempFilteredStore.push(store);
        }
      });
      setIsFilterEnabled(true);
      setFilteredStoreData(tempFilteredStore);
    } else {
      setIsFilterEnabled(false);
    }
  };

  const convertToTitleCase = (inputString) => {
    const words = inputString.split("-");

    const titleCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return titleCaseWords.join(" ");
  };

  const convertToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, ".")
      .replace(/[^a-z0-9.]/g, "")
      .replace(/\.+/g, ".")
      .replace(/(^\.)|(\.$)/g, "");
  };

  const randomizeString = (inputString) => {
    const stringWithoutSpaces = inputString.replace(/\s/g, "");
    const array = stringWithoutSpaces.split("");
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
  };

  const handleSubmissionData = async () => {
    let validateData = await validateSubmission(formData);

    if (!validateData.status) {
      setErrorMessage(validateData);
      setTimeout(() => {
        setErrorMessage([]);
      }, 5000);
    } else {
      let token = await captchaRef.current.executeAsync();
      captchaRef.current.reset();

      if (token) {
        const verifyResult = await processCaptcha(token);

        if (Boolean(verifyResult.success) || verifyResult.score > 0.5) {
          const authData = {
            name: convertToSlug(formData.user_name),
            key: randomizeString(
              convertToSlug(formData.user_name) +
                formData?.phone_number +
                formData?.email_address +
                formData?.dateofbirth +
                formData?.store
            ),
          };
          authenticateUser(authData, formData.phone_number);
        } else {
          setErrorMessage({
            message: "Mohon dicoba lagi, atau selesaikan CAPTCHA ini",
          });
          setTimeout(() => {
            setErrorMessage([]);
          }, 5000);
        }
      }
    }
  };

  const validateSubmission = async (formData) => {
    let checkResult = {
      status: true,
      user_name: "",
      dateofbirth: "",
      email_address: "",
      store: "",
      phone_number: "",
      is_accept: "",
    };

    // eslint-disable-next-line array-callback-return
    Object.entries(formData).some(([key, value]) => {
      if (value === null || value === "" || value === false) {
        switch (key) {
          case "user_name":
            checkResult.user_name = "*Mohon masukan nama anda";
            checkResult.status = false;
            break;

          case "dateofbirth":
            checkResult.dateofbirth =
              "*Mohon masukan tanggal ulang tahun (bulan dan tahun) anda";
            checkResult.status = false;
            break;

          case "store":
            checkResult.store = "*Mohon pilih toko";
            checkResult.status = false;
            break;

          case "phone_number":
            checkResult.phone_number = "*Masukan nomor telepon dengan benar";
            checkResult.status = false;
            break;

          case "email_address":
            checkResult.status = true;
            break;

          case "is_accept":
            checkResult.is_accept =
              "*Silakan baca dan setujui Syarat dan Ketentuan";
            checkResult.status = false;
            break;

          default:
            break;
        }
      } else {
        switch (key) {
          case "phone_number":
            let validPhoneRegex = /^\d{6,14}$/;
            if (!validPhoneRegex.test(value)) {
              checkResult.phone_number = "*Masukan nomor telepon dengan benar";
              checkResult.status = false;
            }
            break;

          case "email_address":
            let validRegex =
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!validRegex.test(value)) {
              checkResult.email_address = "*Masukan alamat email dengan benar";
              checkResult.status = false;
            }
            break;

          default:
            break;
        }
      }
    });

    return checkResult;
  };

  const verifyCaptcha = async (token) => {
    const USER_TOKEN = { token };

    return new Promise((resolve, reject) => {
      mutatePostVerify(USER_TOKEN, {
        onSuccess: (result) => {
          resolve(result.data);
        },
        onError: (err) => {
          reject(err);
        },
      });
    });
  };

  const processCaptcha = async (token) => {
    try {
      const verifiedData = await verifyCaptcha(token);
      return verifiedData.data;
    } catch (error) {
      let deconstructError = errorHandling(error);
      let finalErrMessage = convertToTitleCase(deconstructError);
      setErrorMessage(finalErrMessage);
      setTimeout(() => {
        setErrorMessage([]);
      }, 5000);
    }
  };

  const authenticateUser = (authData) => {
    mutatePostAuthenticate(authData, {
      onSuccess: (result) => {
        localStorage.setItem("loyaltyUserId", result.data.data.id);
        localStorage.setItem("loyaltyPosToken", result.data.data.token);
        localStorage.setItem(
          "loyaltyPosExpiryDate",
          result.data.data.expiryDate
        );
        sendOTPHandler(formData);
      },
      onError: (err) => {
        let deconstructError = errorHandling(err);
        setErrorMessage(deconstructError);
        setModalOpen(true);
      },
    });
  };

  const sendOTPHandler = (formData) => {
    mutateRegister(formData, {
      onSuccess: (result) => {
        toast({
          title: "Success!",
          duration: 750,
          isClosable: true,
          position: "top",
          onCloseComplete: () => {
            localStorage.setItem("registrationData", JSON.stringify(formData));
            localStorage.setItem("loyaltyUserToken", result.data.token);
            localStorage.setItem("userId", result.data.id);
            window.location.assign("/verification");
          },
        });
      },
      onError: (err) => {
        let deconstructError = errorHandling(err);
        setErrorMessage(deconstructError);
        setModalOpen(true);
      },
    });
  };

  const errorHandling = (err) => {
    let errResponse = { type: "", code: "", message: "" };

    if (err.response) {
      if (err.response.data.errorType === "General Error") {
        errResponse.type = err.response.data.errorType;
        errResponse.code = err.response.data.errors[0].code;
        errResponse.message = err.response.data.errors[0].message;
      } else {
        errResponse.type = err.response.data.errorType;
        errResponse.code = err.response.data.status;
        errResponse.message = err.response.data.errorType;
      }
    } else if (err.name && err.message) {
      errResponse.type = err.name;
      errResponse.message = err.message;
    } else {
      errResponse.message = `Unexpected Error`;
    }

    return errResponse;
  };

  const handleInput = (event) => {
    let tempFormData = formData;

    tempFormData[event.target.name] =
      event.target.name === "is_accept"
        ? event.target.checked
        : event.target.value;

    if (
      formData['user_name']
      && formData.dateofbirth
      && formData.store
      && formData['phone_number']
      && formData['is_accept']
    ) {
      setIsRequiredFormIncomplete(false)
    } else {
      setIsRequiredFormIncomplete(true)
    }

    if (event.target.name === "phone_number") {
      const phoneNumber = parseInt(event.target.value)
      tempFormData[event.target.name] = "62".concat(phoneNumber)
    }

    if (event.target.name === "dateofbirth") {
      tempFormData[event.target.name] = event.target.value + "-01";
    }

    setFormData(tempFormData);
  };

  const handleChooseStore = (event) => {
    let tempFormData = formData;
    tempFormData["store"] = event;
    setSelectedStoreName(event);
    setFormData(tempFormData);
    onCloseStoreDrawer();
  };

  moment.locale();

  return (
    <>
      <div className={styles["main-container"]}>
        <Flex className={styles["header-container"]}>
          <MainHeader />
        </Flex>
        <Stack className={styles["form-container"]}>
          <Box className={styles["form-fields"]}>
            <Flex marginBottom={"0.25em"}>
              <Box width={"100%"}>
                <div className={styles["form-field__group"]}>
                  <Flex className={styles["form-field__title-wrapper"]}>
                    <Text className={styles["form-field__title"]}>Nama</Text>
                  </Flex>
                  <Flex>
                    <Input
                      className={styles["open-font"]}
                      onChange={handleInput}
                      variant="flushed"
                      name="user_name"
                      placeholder="Masukkan nama lengkap"
                    />
                  </Flex>
                  {errorMessage && formData.user_name === "" && (
                    <Flex>
                      <Text className={styles["form-field__error"]}>
                        {errorMessage.user_name}
                      </Text>
                    </Flex>
                  )}
                </div>
                <div className={styles["form-field__group"]}>
                  <Flex className={styles["form-field__title-wrapper"]}>
                    <Text className={styles["form-field__title"]}>
                      Pilih Toko
                    </Text>
                  </Flex>
                  <Box className={styles["choose-store"]}>
                    <Button
                      variant="unstyled"
                      size="sm"
                      className={styles["choose-store__button"]}
                      onClick={onOpenStoreDrawer}
                    >
                      <Flex>
                        {selectedStoreName !== "" ? (
                          <Text className={styles["choose-store__selected"]}>
                            {selectedStoreName}
                          </Text>
                        ) : (
                          <Text
                            className={styles["choose-store__button-text"]}
                          >
                            Pilih Toko
                          </Text>
                        )}
                        <Spacer />
                        <Text>
                          <ChevronUpIcon size="xl" />
                        </Text>
                      </Flex>
                    </Button>
                  </Box>
                  {errorMessage && formData.store === "" && (
                    <Flex>
                      <Text className={styles["form-field__error"]}>
                        {errorMessage.store}
                      </Text>
                    </Flex>
                  )}
                </div>
                <div className={styles["form-field__group"]}>
                  <Flex className={styles["form-field__title-wrapper"]}>
                    <Text className={styles["form-field__title"]}>
                      Nomor WhatsApp
                    </Text>
                  </Flex>
                  <Flex>
                    <InputGroup>
                      <InputLeftElement>
                        <Text marginRight="16px">+62</Text>
                      </InputLeftElement>
                      <Input
                        className={styles["open-font"]}
                        type="number"
                        onChange={handleInput}
                        placeholder="Masukkan nomor handphone"
                        variant="flushed"
                        name="phone_number"
                        minLength="8"
                        min="1"
                      />
                    </InputGroup>
                  </Flex>
                  {((!errorMessage.status && errorMessage.phone_number) ||
                    formData.phone_number === "") && (
                    <Flex>
                      <Text className={styles["form-field__error"]}>
                        {errorMessage.phone_number}
                      </Text>
                    </Flex>
                  )}
                </div>
                <div className={styles["form-field__group"]}>
                  <Flex className={styles["form-field__title-wrapper"]}>
                    <Text className={styles["form-field__title"]}>Email</Text>
                  </Flex>
                  <Flex>
                    <Input
                      className={styles["open-font"]}
                      onChange={handleInput}
                      variant="flushed"
                      name="email_address"
                      placeholder="Masukkan alamat email"
                    />
                  </Flex>
                </div>
                <div className={styles["form-field__group"]}>
                  <Flex className={styles["form-field__title-wrapper"]}>
                    <Text className={styles["form-field__title"]}>
                      Ulang Tahun
                    </Text>
                  </Flex>
                  <Flex>
                    <Input
                      className={styles["open-font"]}
                      onChange={handleInput}
                      type="month"
                      variant="flushed"
                      name="dateofbirth"
                      placeholder="Masukkan tanggal ulang tahun anda"
                    />
                  </Flex>
                  {errorMessage && formData.dateofbirth === "" && (
                    <Flex>
                      <Text className={styles["form-field__error"]}>
                        {errorMessage.dateofbirth}
                      </Text>
                    </Flex>
                  )}
                </div>
              </Box>
            </Flex>
          </Box>
          <Box>
            {errorMessage && (
              <Text className={styles["form-field__error"]}>
                {errorMessage.message}
              </Text>
            )}
          </Box>
          <Spacer />
          <Box className={styles["form-button__wrapper"]}>
            <Flex marginBottom={"1em"} gap="5px">
              <Box w="75%">
                <CheckboxGroup>
                  <Checkbox name="is_accept" onChange={handleInput}>
                    <Text className={styles["form-checkbox__text"]}>
                      Saya menyetujui{" "}
                      <span className={styles["form-tnc__text-highlight"]}>
                        Terms and Conditions
                      </span>{" "}
                      dan{" "}
                      <span className={styles["form-tnc__text-highlight"]}>
                        Privacy Policy
                      </span>
                    </Text>
                  </Checkbox>
                </CheckboxGroup>
                {errorMessage && formData.is_accept === false && (
                  <Text className={styles["form-field__error"]}>
                    {errorMessage.is_accept}
                  </Text>
                )}
              </Box>
              <Box w="25%" textAlign="right">
                <button
                  size="sm"
                  className={styles["form-checkbox__detail-text"]}
                  onClick={() => {
                    onOpenTnCDrawer();
                  }}
                >
                  Lihat detail
                </button>
              </Box>
            </Flex>
            <Button
              className={styles["form-button__continue"]}
              bg={"#113A70"}
              onClick={() => {handleSubmissionData()}}
              isLoading={
                isLoadingVerify || isLoadingRegister || isLoadingAuthenticate
              }
              isDisabled={
                (isSuccessVerify && isSuccessRegister && isSuccessAuthenticate) || isRequiredFormIncomplete
              }
            >
              Lanjut
            </Button>
          </Box>
        </Stack>
      </div>
      <TermsConditionsDrawer
        isOpen={isOpenTnCDrawer}
        onClose={onCloseTnCDrawer}
      />
      <StoreDrawer
        selectedStoreName={selectedStoreName}
        options={isFilterEnabled ? filteredStoreData : stores}
        searchProduct={searchProduct}
        handleChooseStore={handleChooseStore}
        isOpen={isOpenStoreDrawer}
        onClose={onCloseStoreDrawer}
      />
      <ErrorModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        errMessage={errorMessage}
      />
      <Box className={styles["form-captcha__wrapper"]}>
        <ReCAPTCHA
          className="recaptcha"
          size="invisible"
          sitekey={reCAPTCHA_SITE_KEY}
          ref={captchaRef}
        />
      </Box>
    </>
  );
}

export default FormRegistration;
