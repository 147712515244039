import { useMutation } from '@tanstack/react-query';
import authAxios from '../../utils/interceptorPOSAuth';

export const usePostSendToCapill = () => {
  return useMutation(async (body) => {
    const res = await authAxios({
      method: 'POST',
      url: `userRegister/${body.userId}`,
      data: {
        'name': body.user_name,
        'dateofbirth': body.dateofbirth,
        'phone': body.phone_number,
        'email': body.email_address,
        'registerSource': body.store,
      }
    });

    return res;
  });
};
